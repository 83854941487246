<template>
  <div>
    <navigation :msg="msg"></navigation>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="萝卜猎手好用吗？" name="1">好用</van-collapse-item>
      <van-collapse-item title="有多好用？" name="2">非常好用</van-collapse-item>
      <van-collapse-item title="可以具体点嘛？" name="3">
          用了之后脚不酸了，上楼也有劲了。
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
export default {
  components: {
    navigation,
  },
  data() {
    return {
      msg: "常见问题",
      activeName:[]
    };
  },
};
</script>
<style scoped>
</style>